const categoryEmojis = {
    'Cakes': '🍰',
    'Cupcakes': '🧁',
    'Cheesecake': '🍰',
    'Quick Bread': '🥖',
    'Toppings & Sauces': '🍯',
    'Breakfast': '🥞',
    'Donuts': '🍩',
    'Frosting': '🍥',
    'Dinner & Sides': '🍽️',
    'Scones': '🥐',
    'Bread': '🍞',
    'Cookies': '🍪',
    'Muffins': '🧁',
    'Pies, Crisps, & Tarts': '🥧',
    'Candy': '🍬',
    'Brownies & Bars': '🍫',
    'Pastries': '🥐',
    'Pasta & Soup': '🍜',
    'Baking Tips': '📖',
    'Sweet Rolls': '🍥',
    'Ice Cream & Frozen Treats': '🍦',
    'Beginner': '👩‍🍳',
    'Savory': '🍲',
    'No-Bake Recipes': '🥣',
    'Appetizers & Snacks': '🍢',
    'Granola & Granola Bars': '🥜',
    'Recipe Round-Ups': '🔄',
    'Drinks': '🥤',
    'Pizza': '🍕',
    'Yeast Bread': '🥖',
    'Summer': '☀️',
    "Sally's Baking Challenge": '🏆',
    'Desserts': '🍮',
    'Healthy': '🥗',
    'Gluten Free': '🚫🌾',
    'Specialty Desserts': '🍮',
    'Christmas': '🎄',
    'Halloween': '🎃',
    'Crisps & Cobblers': '🍎',
    'Seasonal': '🍁',
    'Tart Recipes': '🍋',
    'Thanksgiving': '🦃',
  };


export default categoryEmojis;
