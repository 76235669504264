// src/App.js
import React, { useMemo } from 'react';
import { ThemeProvider, CssBaseline, Container, Box } from '@mui/material';
import { AppProvider, AppContext } from './context/AppContext';
import { lightTheme, darkTheme } from './theme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import About from './components/About';
// Import components directly from the recipes folder
import RecipeFilterContainer from './components/recipes/RecipeFilterContainer';
import RecipeList from './components/recipes/RecipeList';

function AppContent() {
  const { darkMode } = React.useContext(AppContext);

  // Choose the theme based on darkMode state
  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          padding: 2,
          minHeight: '100vh',
        }}
      >
        <Box>
          <Header />
          <Routes>
            {/* Route for the base URL (/) */}
            <Route path="/" element={<HomePage />} />

            {/* Route for /recipes to show RecipeFilterContainer */}
            <Route path="/recipes" element={<RecipeFilterContainer />} />

            {/* New Route for the About page */}
            <Route path="/about" element={<About />} />

            {/* Additional routes for recipe-related pages */}
            <Route path="/recipes/list" element={<RecipeList />} />

            {/* Catch-all route for 404 or not found pages */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
