// src/components/Legend.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@mui/material';

const Legend = ({ selectedCategory }) => {
  // If no category is selected, don't render the legend
  if (!selectedCategory) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      mt={2}
      sx={{ flexWrap: 'wrap' }}
    >
      {/* <Typography variant="body1">Legend:</Typography> */}
      <Chip
        label={`Less Common for ${selectedCategory}`}
        color="lessCommonIngredient"
        sx={{
          padding: '6px 12px',
          fontSize: '0.875rem',
        }}
      />
      <Chip
        label={`Common for ${selectedCategory}`}
        color="commonIngredient"
        sx={{
          padding: '6px 12px',
          fontSize: '0.875rem',
        }}
      />
    </Box>
  );
};

Legend.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
};

export default Legend;
