// src/components/HomePage.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom>
        Advanced Baking Recipe Filter
      </Typography>
      <Typography variant="h5">
       Seamlessly filter Sally’s Baking Addiction recipes to find your next favorite treat.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 4 }}
        component={Link}
        to="/recipes"
      >
        View Recipes
      </Button>
    </Box>
  );
}

export default HomePage;
