// src/components/CategorySelection.js
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const CategorySelection = ({
  categories,
  categoryEmojis,
  selectedCategory,
  selectCategory,
  deselectCategory,
}) => {
  const handleChange = (event) => {
    const value = event.target.value;
    if (value === '') {
      deselectCategory();
    } else {
      selectCategory(value);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="category-select-label">Select Category</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={selectedCategory}
          onChange={handleChange}
          label="Select Category"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {categoryEmojis[category] ? `${categoryEmojis[category]} ${category}` : category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CategorySelection.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  categoryEmojis: PropTypes.object.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  deselectCategory: PropTypes.func.isRequired,
};

export default CategorySelection;
