import React from 'react';
import { Button, Typography } from '@mui/material';
import CoffeeIcon from '@mui/icons-material/Coffee';

function Coffee() {
  return (
    <Button
      variant="contained"
      color="warning"
      href="https://www.buymeacoffee.com/obscurefjord"
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        height: '5rem',
        fontSize: '1.5rem',
        textTransform: 'none',
        padding: '0.7rem 1rem',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '5px',
      }}
    >
      <CoffeeIcon fontSize="large" sx={{ marginRight: '10px' }} />
      <Typography variant="h5" component="span">
        Buy me a coffee
      </Typography>
    </Button>
  );
}

export default Coffee;
