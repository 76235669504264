import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { AppContext } from '../context/AppContext';

function Header() {
  const { darkMode, toggleDarkMode } = useContext(AppContext);

  return (
    <AppBar position="static" color="default" sx={{ marginBottom: 2 }}>
      <Toolbar>
        {/* Logo or Site Name */}
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
        >
          Obscure Fjord
        </Typography>

        {/* Navigation Links */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button component={Link} to="/" color="inherit">
            Home
          </Button>
          <Button component={Link} to="/about" color="inherit">
            About
          </Button>
         
          {/* Theme Toggle Button */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={toggleDarkMode}
            aria-label="toggle theme"
          >
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
