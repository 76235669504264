// src/components/RecipeFilterContainer.js
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import IngredientSelection from './IngredientSelection';
import CategorySelection from './CategorySelection';
import IngredientSelectionToggle from './IngredientSelectionToggle';
import SortingControls from './SortingControls';
import Legend from './Legend'; // Import Legend
import categoryEmojis from '../../data/category_emojis';
import RecipeList from './RecipeList';
import RecipeAppHeader from './RecipeAppHeader';
import Grid from '@mui/material/Grid'; 
import { Box, Paper, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

function RecipeFilterContainer() {
  const {
    categories,
    selectedCategory,
    deselectCategory,
    selectCategory,
    showIngredientSelection,
    setShowIngredientSelection,
    availableIngredients,
    ingredientStates,
    toggleIngredient,
    sortOption,
    setSortOption,
    darkMode,         // Access darkMode from context
    toggleDarkMode,   // Access toggleDarkMode from context
    selectedTotalTimeFilter,    // Access selectedTotalTimeFilter from context
    setSelectedTotalTimeFilter, // Access setSelectedTotalTimeFilter from context
  } = useContext(AppContext);

  // Handle total_time dropdown change
  const handleTotalTimeChange = (event) => {
    setSelectedTotalTimeFilter(event.target.value);
  };

  return (
    <>
      <RecipeAppHeader/>
      <Box sx={{ marginY: 4 }}>
        {/* Unified Filter Container */}
        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, backgroundColor: 'background.paper' }}>
          {/* First Row: Category Selection, Total Time Filter, Sorting Controls */}
          <Grid container spacing={2} alignItems="center">
            {/* Category Selection Dropdown */}
            <Grid item xs={12} sm={4}>
              <CategorySelection
                categories={categories}
                categoryEmojis={categoryEmojis}
                selectedCategory={selectedCategory}
                selectCategory={selectCategory}
                deselectCategory={deselectCategory}
              />
            </Grid>

            {/* Total Time Filter */}
            {selectedCategory && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="total-time-filter-label">Total Time</InputLabel>
                  <Select
                    labelId="total-time-filter-label"
                    id="total-time-filter"
                    value={selectedTotalTimeFilter}
                    onChange={handleTotalTimeChange}
                    label="Total Time"
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="<30">&lt; 30 min</MenuItem>
                    <MenuItem value="30-60">30 min to 1 hour</MenuItem>
                    <MenuItem value=">120">Over 2 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Sorting Controls */}
            {selectedCategory && (
              <Grid item xs={12} sm={4}>
                <SortingControls
                  sortOption={sortOption}
                  setSortOption={setSortOption}
                />
              </Grid>
            )}
          </Grid>

          {/* Second Row: Ingredient Selection Toggle (Own Row) */}
          {selectedCategory && (
            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  {/* Ingredient Selection Toggle */}
                  <IngredientSelectionToggle
                    showIngredientSelection={showIngredientSelection}
                    setShowIngredientSelection={setShowIngredientSelection}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Third Row: Legend and Dark Mode Toggle */}
          {selectedCategory && (
            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2} alignItems="center">
                {/* Legend Section */}
                <Grid item xs={12} md={6}>
                  <Legend selectedCategory={selectedCategory} />
                </Grid>

                {/* Dark Mode Toggle */}
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, mt: { xs: 2, md: 0 } }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={darkMode}
                          onChange={toggleDarkMode}
                          color="primary"
                          inputProps={{ 'aria-label': 'Toggle dark mode' }}
                        />
                      }
                      label="Dark Mode"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>

        {/* Ingredient Selection Section */}
        {selectedCategory && showIngredientSelection && (
          <Box sx={{ marginTop: 2 }}>
            <IngredientSelection
              availableIngredients={availableIngredients}
              ingredientStates={ingredientStates}
              toggleIngredient={toggleIngredient}
            />
          </Box>
        )}

        {/* Display Filtered Recipes */}
        {selectedCategory ? (
          <Box sx={{ marginTop: 4 }}>
            <RecipeList /> {/* RecipeList accesses filteredRecipes via context */}
          </Box>
        ) : (
          <Box sx={{ marginY: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              Please select a category to view recipes.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default RecipeFilterContainer;
