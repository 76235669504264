// src/components/IngredientSelection.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Paper,
  Tooltip,
  useTheme,
  Divider,
} from '@mui/material';
import { CheckCircle, Cancel, AddCircle } from '@mui/icons-material';
import ingredientIdLookup from '../../data/ingredient_id_lookup.json'; // Import the lookup
import coreIngredientMapping from '../../data/core_ingredient_mapping.json'; // Import the core ingredient mapping

const IngredientSelection = ({
  availableIngredients,
  ingredientStates,
  toggleIngredient,
}) => {
  const theme = useTheme(); // Access the theme

  // Define a helper function to get the icon based on state
  const getIcon = (state) => {
    switch (state) {
      case 'include':
        return <CheckCircle fontSize="small" />;
      case 'exclude':
        return <Cancel fontSize="small" />;
      default:
        return <AddCircle fontSize="small" />;
    }
  };

  // Define a helper function to get tooltip text based on state
  const getTooltipText = (state, ingredientName) => {
    switch (state) {
      case 'neutral':
        return `Include "${ingredientName}"`;
      case 'include':
        return `Exclude "${ingredientName}"`;
      case 'exclude':
        return `Reset "${ingredientName}"`;
      default:
        return `Include "${ingredientName}"`;
    }
  };

  // Function to get variations for a given base ingredient
  const getVariations = (baseIngredient) => {
    return coreIngredientMapping[baseIngredient.toLowerCase()] || [];
  };

  // Compile lists of included and excluded variations
  const includedVariations = [];
  const excludedVariations = [];

  availableIngredients.forEach((ingredientName) => {
    // Find the ingredient ID from the name
    const ingredientId = Object.keys(ingredientIdLookup).find(
      (id) => ingredientIdLookup[id].n.toLowerCase() === ingredientName.toLowerCase()
    );

    // Handle case where ingredientId might not be found
    if (!ingredientId) {
      console.warn(`Ingredient "${ingredientName}" not found in lookup.`);
      return; // Skip rendering this ingredient
    }

    const state = ingredientStates[ingredientId] || 'neutral';

    if (state === 'include') {
      const variations = getVariations(ingredientName);
      includedVariations.push(...variations);
    } else if (state === 'exclude') {
      const variations = getVariations(ingredientName);
      excludedVariations.push(...variations);
    }
  });

  // Remove duplicates by converting to Sets and back to Arrays
  const uniqueIncludedVariations = Array.from(new Set(includedVariations));
  const uniqueExcludedVariations = Array.from(new Set(excludedVariations));

  return (
    <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>
        Select Core Ingredients
      </Typography>
      <Typography variant="body2" paragraph>
        Click to include an ingredient. Click again to exclude it.
      </Typography>

      <Box
        className="ingredient-buttons"
        display="flex"
        flexWrap="wrap"
        gap={0.5} // Smaller gap between buttons
        justifyContent="flex-start"
        alignItems="center"
      >
        {availableIngredients.map((ingredientName) => {
          // Find the ingredient ID from the name
          const ingredientId = Object.keys(ingredientIdLookup).find(
            (id) => ingredientIdLookup[id].n.toLowerCase() === ingredientName.toLowerCase()
          );

          // Handle case where ingredientId might not be found
          if (!ingredientId) {
            console.warn(`Ingredient "${ingredientName}" not found in lookup.`);
            return null; // Skip rendering this ingredient
          }

          const state = ingredientStates[ingredientId] || 'neutral';

          // Define button styles based on state using theme.palette
          const buttonStyles = {
            ingredientInclude: {
              backgroundColor: theme.palette.ingredientInclude.main,
              color: theme.palette.ingredientInclude.contrastText,
              border: 'none',
            },
            ingredientExclude: {
              backgroundColor: theme.palette.ingredientExclude.main,
              color: theme.palette.ingredientExclude.contrastText,
              border: 'none',
            },
            ingredientNeutral: {
              backgroundColor: theme.palette.ingredientNeutral.main,
              color: theme.palette.ingredientNeutral.contrastText,
              border: `1px solid ${theme.palette.ingredientNeutral.main}`,
            },
          };

          // Determine the current button style
          const currentButtonStyle =
            state === 'include'
              ? buttonStyles.ingredientInclude
              : state === 'exclude'
              ? buttonStyles.ingredientExclude
              : buttonStyles.ingredientNeutral;

          // Define hover styles
          const hoverStyles =
            state === 'neutral'
              ? { backgroundColor: theme.palette.action.hover }
              : state === 'include'
              ? { backgroundColor: theme.palette.ingredientInclude.dark || theme.palette.ingredientInclude.main }
              : { backgroundColor: theme.palette.ingredientExclude.dark || theme.palette.ingredientExclude.main };

          return (
            <Tooltip
              key={ingredientId}
              title={getTooltipText(state, ingredientName)}
              arrow
              placement="top"
            >
              <Button
                onClick={() => toggleIngredient(ingredientId)}
                variant="contained"
                sx={{
                  ...currentButtonStyle,
                  textTransform: 'none',
                  borderRadius: '16px', // Slightly smaller radius
                  padding: '4px 8px', // Smaller padding for a compact look
                  minWidth: { xs: '100px', sm: '120px', md: '140px' }, // Reduced width
                  height: '40px', // Fixed height, reduced for compactness
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  boxShadow: 'none',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    boxShadow: 'none',
                    ...hoverStyles,
                  },
                }}
                aria-pressed={state !== 'neutral'}
                aria-label={
                  state === 'include'
                    ? `Exclude ${ingredientName}`
                    : state === 'exclude'
                    ? `Reset ${ingredientName}`
                    : `Include ${ingredientName}`
                }
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {getIcon(state)}
                  <Typography
                    variant="body2" // Smaller font size for more compactness
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '100%',
                    }}
                  >
                    {ingredientName}
                  </Typography>
                </Box>
              </Button>
            </Tooltip>
          );
        })}
      </Box>

      {/* Divider */}
      <Divider sx={{ marginY: 2 }} />

      {/* Summary Section */}
      {(uniqueIncludedVariations.length > 0 || uniqueExcludedVariations.length > 0) && (
        <Box>
          {uniqueIncludedVariations.length > 0 && (
            <Box sx={{ marginBottom: 1 }}>
              <Typography variant="subtitle1" color="green">
                You are <strong>including</strong>:
              </Typography>
              <Typography variant="body2">
                {uniqueIncludedVariations.join(', ')}
              </Typography>
            </Box>
          )}
          {uniqueExcludedVariations.length > 0 && (
            <Box>
              <Typography variant="subtitle1" color="red">
                You are <strong>excluding</strong>:
              </Typography>
              <Typography variant="body2">
                {uniqueExcludedVariations.join(', ')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

IngredientSelection.propTypes = {
  availableIngredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  ingredientStates: PropTypes.object.isRequired,
  toggleIngredient: PropTypes.func.isRequired,
};

export default IngredientSelection;
