// src/components/RecipeList.js
import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import Grid from '@mui/material/Grid'; // Use the stable Grid component
import Recipe from './Recipe';
import { AppContext } from '../../context/AppContext';

const RECIPES_PER_PAGE = 6; // Adjust as needed

const RecipeList = () => {
  const { filteredRecipes, selectedCategory } = useContext(AppContext);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages
  const totalPages = Math.ceil(filteredRecipes.length / RECIPES_PER_PAGE);

  // Get current page's recipes
  const indexOfLastRecipe = currentPage * RECIPES_PER_PAGE;
  const indexOfFirstRecipe = indexOfLastRecipe - RECIPES_PER_PAGE;
  const currentRecipes = filteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

  // Reset to first page when filteredRecipes or selectedCategory changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredRecipes, selectedCategory]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (!filteredRecipes || filteredRecipes.length === 0) {
    return (
      <Typography variant="body1" color="text.secondary" align="center">
        No recipes to display.
      </Typography>
    );
  }

  return (
    <Box className="filtered-recipes" sx={{ padding: 2, width: '100%' }}>
      {/* Recipe Card Container */}
      <Grid
        container
        spacing={2}
        className="recipe-card-container"
        sx={{ marginTop: 2 }}
      >
        {currentRecipes.map((recipe) => (
          <Grid item xs={12} sm={12} md={12} key={recipe.id}>
            <Recipe recipe={recipe} category={selectedCategory} />
          </Grid>
        ))}
      </Grid>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 4,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

export default RecipeList;
