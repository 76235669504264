// src/components/Recipe.js
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Box, Chip, Typography, CardActions, Button } from '@mui/material';
import RecipeDetails from './RecipeDetails';
import ingredientIdLookup from '../../data/ingredient_id_lookup.json';

const Recipe = ({ recipe, category }) => {
  const sortedIngredients = recipe.ingredients
    .filter(
      (ingredientObj) =>
        ingredientObj.i !== null && ingredientIdLookup[ingredientObj.i]?.n
    )
    .map((ingredientObj) => {
      const ingredient = ingredientIdLookup[ingredientObj.i].n;
      const tag = ingredientIdLookup[ingredientObj.i].t?.[category] || 'neutral';
      return {
        id: ingredientObj.i,
        name: ingredient,
        tag: tag,
      };
    })
    .sort((a, b) => {
      const tagPriority = { l: 1, c: 2, neutral: 3 };
      return (tagPriority[a.tag] || 4) - (tagPriority[b.tag] || 4);
    });

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: 2,
        height: '100%', // Ensures the Card fills the Grid item's height
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        {/* Recipe Title */}
        <Typography
          variant="h5"
          component="a"
          href={recipe.u}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {recipe.t || 'Unknown Title'}
        </Typography>

        {/* Recipe Details */}
        <RecipeDetails recipe={recipe} />

        {/* Ingredients */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            mt: 2,
          }}
        >
          {sortedIngredients.map(({ id, name, tag }) => (
            <Chip
              key={id}
              label={name}
              color={
                tag === 'c'
                  ? 'commonIngredient'
                  : tag === 'l'
                  ? 'lessCommonIngredient'
                  : 'default' // Changed to 'default' for 'neutral'
              }
              variant={tag === 'neutral' ? 'outlined' : 'filled'}
              sx={{ fontSize: '0.875rem' }}
            />
          ))}
        </Box>
      </CardContent>

      {/* Actions like View Recipe Button */}
      <CardActions>
        <Button
          size="small"
          href={recipe.u}
          target="_blank"
          rel="noopener"
          sx={{ marginLeft: 'auto' }} // Aligns the button to the right
        >
          View Recipe
        </Button>
      </CardActions>
    </Card>
  );
};

Recipe.propTypes = {
  recipe: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string, // For UUIDs or URLs
      PropTypes.number, // For incremental IDs
    ]).isRequired,
    tm: PropTypes.string,
    t: PropTypes.string,
    y: PropTypes.string,
    p: PropTypes.string,
    u: PropTypes.string.isRequired,
    c: PropTypes.string,
    ingredients: PropTypes.arrayOf(
      PropTypes.shape({
        i: PropTypes.number, // Ingredient ID
        pi: PropTypes.number, // Parent Ingredient ID
      })
    ),
    s: PropTypes.string,
    r: PropTypes.string,
    description: PropTypes.string,
    ttm: PropTypes.number, // Added 'ttm' as number
  }).isRequired,
  category: PropTypes.string.isRequired,
};

export default Recipe;
