// src/components/RecipeDetails.js
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Tooltip } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StarIcon from '@mui/icons-material/Star';
import ReviewIcon from '@mui/icons-material/RateReview';
import EventIcon from '@mui/icons-material/Event';
import Grid from '@mui/material/Grid2';


const RecipeDetails = ({ recipe }) => (
  <Grid container spacing={2}>
    {/* Published Date */}
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Tooltip title="Published Date">
          <ScheduleIcon color="action" sx={{ mr: 1 }} />
        </Tooltip>
        <Typography variant="subtitle1" fontWeight="bold">
          Published Date:
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {recipe.p ? new Date(recipe.p).toLocaleDateString() : 'N/A'}
        </Typography>
      </Box>
    </Grid>

    {/* Total Time */}
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Tooltip title="Total Time">
          <EventIcon color="action" sx={{ mr: 1 }} />
        </Tooltip>
        <Typography variant="subtitle1" fontWeight="bold">
          Total Time:
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {recipe.tm || 'N/A'}
        </Typography>
      </Box>
    </Grid>

    {/* Yield */}
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Tooltip title="Yield">
          <RestaurantIcon color="action" sx={{ mr: 1 }} />
        </Tooltip>
        <Typography variant="subtitle1" fontWeight="bold">
          Yield:
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {recipe.y || 'N/A'}
        </Typography>
      </Box>
    </Grid>

    {/* Rating */}
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Tooltip title="Rating">
          <StarIcon color="action" sx={{ mr: 1 }} />
        </Tooltip>
        <Typography variant="subtitle1" fontWeight="bold">
          Rating:
        </Typography>
        <Box display="flex" alignItems="center" sx={{ marginLeft: 1 }}>
          <Typography variant="subtitle1">
            {recipe.s || 'N/A'}
          </Typography>
          {recipe.s && (
            <StarIcon sx={{ color: '#FFD700', ml: 0.5 }} />
          )}
        </Box>
      </Box>
    </Grid>

    {/* Reviews */}
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        <Tooltip title="Reviews">
          <ReviewIcon color="action" sx={{ mr: 1 }} />
        </Tooltip>
        <Typography variant="subtitle1" fontWeight="bold">
          Reviews:
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {recipe.r ? `${recipe.r} Reviews` : 'N/A'}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

RecipeDetails.propTypes = {
  recipe: PropTypes.shape({
    p: PropTypes.string, // Published date
    tm: PropTypes.string, // Total time
    y: PropTypes.string, // Yield
    s: PropTypes.string, // Rating
    r: PropTypes.string, // Reviews
  }).isRequired,
};

export default RecipeDetails;
