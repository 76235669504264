// src/theme.js
import { createTheme } from '@mui/material/styles';

// Light Theme Configuration
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#815355',
    },
    secondary: {
      main: '#3E5641',
    },
    background: {
      default: '#f2f2f2', // Global background color
      paper: '#ffffff',    // Background for paper components
    },
    commonIngredient: {
      main: '#A8A9A9',
      contrastText: '#000',
    },
    lessCommonIngredient: {
      main: '#3E5641',
      contrastText: '#fff',
    },
    neutral: {
      main: '#9e9e9e',
      contrastText: '#fff',
    },
    // Custom Ingredient Colors
    ingredientInclude: {
      main: '#4caf50',       // Green
      dark: '#388e3c',       // Darker Green for hover
      contrastText: '#fff',
    },
    ingredientExclude: {
      main: '#f44336',       // Red
      dark: '#d32f2f',       // Darker Red for hover
      contrastText: '#fff',
    },
    ingredientNeutral: {
      main: '#9e9e9e',       // Gray
      dark: '#757575',       // Darker Gray for hover
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Set the global font family here
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    // Define other typography variants as needed
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { color: 'commonIngredient' },
          style: {
            backgroundColor: '#A8A9A9',
            color: '#000',
          },
        },
        {
          props: { color: 'lessCommonIngredient' },
          style: {
            backgroundColor: '#3E5641',
            color: '#fff',
          },
        },
        {
          props: { color: 'neutral' },
          style: {
            backgroundColor: '#9e9e9e',
            color: '#fff',
          },
        },
      ],
    },
    // Add more component overrides if necessary
  },
});

// Dark Theme Configuration
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#a17c7e',
    },
    secondary: {
      main: '#679267',
    },
    background: {
      default: '#121212',  // Dark global background
      paper: '#1e1e1e',    // Background for paper components
    },
    commonIngredient: {
      main: '#A8A9A9',
      contrastText: '#000',
    },
    lessCommonIngredient: {
      main: '#679267',
      contrastText: '#fff',
    },
    neutral: {
      main: '#6e6e6e',
      contrastText: '#fff',
    },
    // Custom Ingredient Colors for Dark Mode
    ingredientInclude: {
      main: '#66bb6a',       // Lighter Green
      dark: '#388e3c',       // Darker Green for hover
      contrastText: '#fff',
    },
    ingredientExclude: {
      main: '#ef5350',       // Lighter Red
      dark: '#d32f2f',       // Darker Red for hover
      contrastText: '#fff',
    },
    ingredientNeutral: {
      main: '#757575',       // Darker Gray
      dark: '#616161',       // Even Darker Gray for hover
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    // Define other typography variants as needed
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { color: 'commonIngredient' },
          style: {
            backgroundColor: '#A8A9A9',
            color: '#000',
          },
        },
        {
          props: { color: 'lessCommonIngredient' },
          style: {
            backgroundColor: '#679267',
            color: '#fff',
          },
        },
        {
          props: { color: 'neutral' },
          style: {
            backgroundColor: '#6e6e6e',
            color: '#fff',
          },
        },
      ],
    },
    // Add more component overrides if necessary
  },
});

export { lightTheme, darkTheme };
