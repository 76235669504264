// src/context/AppContext.js
import React, { createContext, useState, useEffect, useMemo } from 'react';
import recipesData from '../data/transformed_recipes_with_ids.json';
import ingredientIdLookup from '../data/ingredient_id_lookup.json';
import categoryEmojis from '../data/category_emojis';

import { lightTheme, darkTheme } from '../theme'; // Import themes

const AppContext = createContext();

const AppProvider = ({ children }) => {
  // Theme state
  const [darkMode, setDarkMode] = useState(false);
  
  // Load saved theme preference from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      setDarkMode(true);
    }
  }, []);

  // Toggle theme and save preference
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  };

  // Selected Category State
  const [selectedCategory, setSelectedCategory] = useState('Cookies');
  const [ingredientStates, setIngredientStates] = useState({});
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [sortOption, setSortOption] = useState('date-desc');
  const [showIngredientSelection, setShowIngredientSelection] = useState(false);

  // New: Total Time (ttm) Filter State
  const [selectedTotalTimeFilter, setSelectedTotalTimeFilter] = useState('all'); // 'all', '<30', '30-60', '>120'

  // Preprocess the categories to save computation
  const categories = useMemo(() => {
    return Array.from(new Set(recipesData.map((recipe) => recipe.c))).sort();
  }, []);

  // Get all recipes for the selected category
  const recipesByCategory = useMemo(() => {
    return selectedCategory
      ? recipesData.filter((recipe) => recipe.c === selectedCategory)
      : recipesData;
  }, [selectedCategory]);

  // Get all ingredients based on the selected category
  const getAllIngredients = (recipeList) => {
    const ingredientMap = {};
    recipeList.forEach((recipe) => {
      recipe.ingredients.forEach((ingredientObj) => {
        const ingredientId = ingredientObj.pi;
        if (ingredientId !== null) {
          ingredientMap[ingredientId] = true;
        }
      });
    });

    const ingredientNames = Object.keys(ingredientMap)
      .map((id) => ingredientIdLookup[id]?.n)
      .filter((name) => name !== undefined);

    return ingredientNames.sort();
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
    setIngredientStates({});
    setSortOption('date-desc');
    setShowIngredientSelection(false);

    const filtered = recipesData.filter((recipe) => recipe.c === category);
    setFilteredRecipes(sortRecipes(filtered, 'date-desc'));
    setAvailableIngredients(getAllIngredients(filtered));

    // Reset total time filter when category changes
    setSelectedTotalTimeFilter('all');
  };

  const deselectCategory = () => {
    setSelectedCategory('');
    setFilteredRecipes([]);
    setAvailableIngredients([]);
    setSortOption('date-desc');
    setShowIngredientSelection(false);
    
    // Reset total time filter to 'all'
    setSelectedTotalTimeFilter('all');
  };

  const toggleIngredient = (ingredientId) => {
    const currentState = ingredientStates[ingredientId] || 'neutral';
    const newState =
      currentState === 'neutral'
        ? 'include'
        : currentState === 'include'
        ? 'exclude'
        : 'neutral';

    const newIngredientStates = { ...ingredientStates };
    newIngredientStates[ingredientId] = newState;

    setIngredientStates(newIngredientStates);
    filterRecipes(newIngredientStates, sortOption, selectedTotalTimeFilter);
  };

  const filterRecipes = (ingredientStates, sortOption, totalTimeFilter) => {
    const includedIds = Object.keys(ingredientStates).filter(
      (id) => ingredientStates[id] === 'include'
    );
    const excludedIds = Object.keys(ingredientStates).filter(
      (id) => ingredientStates[id] === 'exclude'
    );

    let filtered = recipesByCategory.filter((recipe) => {
      const recipeIngredientIds = recipe.ingredients
        .map((ingredientObj) => ingredientObj.pi)
        .filter((pi) => pi !== null);

      const hasIncluded = includedIds.every((id) =>
        recipeIngredientIds.includes(parseInt(id))
      );
      const hasExcluded = excludedIds.some((id) =>
        recipeIngredientIds.includes(parseInt(id))
      );

      const withinTtm = applyTotalTimeFilter(recipe.ttm, totalTimeFilter);

      return hasIncluded && !hasExcluded && withinTtm;
    });

    filtered = sortRecipes(filtered, sortOption);
    setFilteredRecipes(filtered);
  };

  const applyTotalTimeFilter = (ttm, filter) => {
    if (!ttm || typeof ttm !== 'number') return false;

    switch (filter) {
      case '<30':
        return ttm < 30;
      case '30-60':
        return ttm >= 30 && ttm <= 60;
      case '>120':
        return ttm > 120;
      case 'all':
      default:
        return true;
    }
  };

  const sortRecipes = (recipesToSort, option) => {
    const sorted = [...recipesToSort];
    switch (option) {
      case 'date-desc':
        sorted.sort((a, b) => new Date(b.p) - new Date(a.p));
        break;
      case 'date-asc':
        sorted.sort((a, b) => new Date(a.p) - new Date(b.p));
        break;
      case 'rating-desc':
        sorted.sort((a, b) => parseFloat(b.s) - parseFloat(a.s));
        break;
      case 'rating-asc':
        sorted.sort((a, b) => parseFloat(a.s) - parseFloat(b.s));
        break;
      case 'ttm-asc':
        sorted.sort((a, b) => (a.ttm || 0) - (b.ttm || 0));
        break;
      case 'ttm-desc':
        sorted.sort((a, b) => (b.ttm || 0) - (a.ttm || 0));
        break;
      default:
        break;
    }
    return sorted;
  };

  // Handle sorting when sortOption changes
  useEffect(() => {
    const sorted = sortRecipes(filteredRecipes, sortOption);
    setFilteredRecipes(sorted);
  }, [sortOption]);

  // Handle total time filter changes
  useEffect(() => {
    filterRecipes(ingredientStates, sortOption, selectedTotalTimeFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTotalTimeFilter]);

  // On initial mount, set filteredRecipes based on default category
  useEffect(() => {
    if (selectedCategory) {
      const initialFiltered = recipesData.filter(
        (recipe) => recipe.c === selectedCategory
      );
      setFilteredRecipes(sortRecipes(initialFiltered, sortOption));
      setAvailableIngredients(getAllIngredients(initialFiltered));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on mount

  return (
    <AppContext.Provider
      value={{
        categories,
        selectedCategory,
        deselectCategory,
        selectCategory,
        showIngredientSelection,
        setShowIngredientSelection,
        availableIngredients,
        ingredientStates,
        toggleIngredient,
        sortOption,
        setSortOption,
        filteredRecipes,
        categoryEmojis,
        darkMode,         // Expose darkMode
        toggleDarkMode,   // Expose toggleDarkMode
        selectedTotalTimeFilter, // Expose selectedTotalTimeFilter
        setSelectedTotalTimeFilter, // Expose setSelectedTotalTimeFilter
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
