// src/components/Header.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

const Header = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: { xs: 3, md: 6 },
        backgroundImage: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
        borderRadius: 2,
        boxShadow: 3,
        marginBottom: 4,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
          letterSpacing: 2,
          color: 'text.primary',
          fontWeight: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <SearchIcon fontSize="large" color="primary" />
        Sally's Recipe Finder
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: 'text.secondary',
          marginTop: 1,
        }}
      >
        Advanced Filtering for Sally's Baking Addiction Recipes
      </Typography>
    </Box>
  );
};

export default Header;
