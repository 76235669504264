// src/components/IngredientSelectionToggle.js
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const IngredientSelectionToggle = ({ showIngredientSelection, setShowIngredientSelection }) => {
  const handleToggle = () => {
    setShowIngredientSelection(!showIngredientSelection);
  };

  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={handleToggle}
      startIcon={showIngredientSelection ? <ExpandLess /> : <ExpandMore />}
      sx={{
        textTransform: 'none',
        borderRadius: '8px',
        padding: '8px 16px',
      }}
      aria-expanded={showIngredientSelection}
      aria-label="Toggle ingredient selection"
    >
      {showIngredientSelection ? 'Hide Ingredients' : 'Show Ingredients'}
    </Button>
  );
};

IngredientSelectionToggle.propTypes = {
  showIngredientSelection: PropTypes.bool.isRequired,
  setShowIngredientSelection: PropTypes.func.isRequired,
};

export default IngredientSelectionToggle;
