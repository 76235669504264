// src/components/SortingControls.js
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const SortingControls = ({ sortOption, setSortOption }) => (
  <Box sx={{ width: '100%' }}>
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="sort-select-label">Sort Recipes By</InputLabel>
      <Select
        labelId="sort-select-label"
        id="sort-select"
        value={sortOption}
        onChange={(e) => setSortOption(e.target.value)}
        label="Sort Recipes By"
        sx={{
          borderRadius: '8px',
        }}
      >
        <MenuItem value="date-desc">Published Date (Newest First)</MenuItem>
        <MenuItem value="date-asc">Published Date (Oldest First)</MenuItem>
        <MenuItem value="rating-desc">Rating (High to Low)</MenuItem>
        <MenuItem value="rating-asc">Rating (Low to High)</MenuItem>
      </Select>
    </FormControl>
  </Box>
);

SortingControls.propTypes = {
  sortOption: PropTypes.string.isRequired,
  setSortOption: PropTypes.func.isRequired,
};

export default SortingControls;
